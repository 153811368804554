<template>
  <div class="e-overall e-overall--login">
    <transition name="slide-to-left">
      <div
        v-show="!offcanvasOpen"
        class="e-canvas"
      >
        <div
          v-if="!$route.meta.requiresAuth"
          class="e-canvas__background"
        />

        <TheHeader />

        <component :is="formType" />

        <TheFooter hide-logos />
      </div>
    </transition>

    <transition name="slide-from-right">
      <TheOffcanvas v-show="offcanvasOpen" />
    </transition>

    <el-dialog
      :title="dialogTitle.registered"
      :visible.sync="registerDialog"
      custom-class="el-dialog--small"
    >
      <p v-html="$route.query.email === '0' ? $t('messages.user.register.successNoEmail') : $t('messages.user.register.success')" />

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="tertiary"
          @click="registerDialog = false"
        >
          {{ $t('system.understood') }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="dialogTitle.activated"
      :visible.sync="activationDialog"
      custom-class="el-dialog--small"
    >
      <p v-html="$t('messages.user.activation.success')" />

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="tertiary"
          @click="activationDialog = false"
        >
          {{ $t('system.understood') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TheFooter from 'molecules/TheFooter/TheFooter'
import TheHeader from 'molecules/TheHeader/TheHeader'
import LoginForm from 'organisms/Forms/Login/Loginform'
import PasswordForm from 'organisms/Forms/PasswordForgot/PasswordForgot'
import PasswordResetForm from 'organisms/Forms/PasswordReset/PasswordReset'
import ResendConfirm from 'organisms/Forms/ResendConfirm/ResendConfirm'
import UserForm from 'organisms/Forms/UserForgot/UserForgot'
import TheOffcanvas from 'molecules/TheOffcanvas/TheOffcanvas'

export default {
  components: {
    TheFooter,
    TheHeader,
    LoginForm,
    TheOffcanvas,
    PasswordResetForm,
    PasswordForm,
    ResendConfirm,
    UserForm
  },
  data () {
    return {
      registerDialog: false,
      activationDialog: false,
      errorMessage: '',
      dialogTitle: {
        registered: this.$t('messages.user.register.successTitle'),
        activated: this.$t('messages.user.activation.title')
      }
    }
  },
  computed: {
    ...mapGetters(['offcanvasOpen', 'userInfo']),
    formType () {
      switch (this.$route.name) {
        case 'forgotPassword':
          return 'PasswordForm'

        case 'resetPassword':
          return 'PasswordResetForm'

        case 'resendConfirmMail':
          return 'ResendConfirm'

        case 'forgotUsername':
          return 'UserForm'

        default:
          return 'LoginForm'
      }
    }
  },
  mounted () {
    if (this.$route.query.registered === 'true') {
      this.registerDialog = true
    } else if (this.$route.query.activated === 'true') {
      this.activationDialog = true
    }
  }
}
</script>

<style lang="scss">
@import '_assets/userlogin';
</style>
